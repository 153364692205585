<template>
  <v-row
    dense
    align="center"
    justify="end"
  >
    <v-col cols="12">
      <v-row justify="end">
        <v-col cols="auto">
          <v-menu
            ref="menuStartDate"
            v-model="menuStartDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                hide-details
                outlined
                class="caption"
                :placeholder="$_strings.common.FROM_DATE"
                :value="dayjs(filter.startDate).format('DD-MM-YYYY')"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filter.startDate"
              no-title
              locale="id"
              :max="filter.endDate"
              @change="menuStartDate = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="d-none d-sm-flex align-center px-0" cols="auto">-</v-col>
        <v-col cols="auto">
          <v-menu
            ref="menuEndDate"
            v-model="menuEndDate"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                hide-details
                class="caption"
                outlined
                :value="dayjs(filter.endDate).format('DD-MM-YYYY')"
                :placeholder="$_strings.common.TO_DATE"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :min="filter.startDate"
              v-model="filter.endDate"
              no-title
              @change="menuEndDate = false"
              locale="id"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-text-field
        v-model="name"
        dense
        hide-details
        outlined
        class="caption"
        @keyup.native.enter="applyFilters"
        :placeholder="$_strings.holiday.SEARCH_HOLIDAY"
      >
        <template v-slot:prepend-inner>
          <v-icon @click="applyFilters">
            mdi-magnify
          </v-icon>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'filter-holiday',
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      name: this.$route.query.name || '',
      menuStartDate: false,
      menuEndDate: false,
    };
  },
  watch: {
    'filter.startDate': function startDate(newVal, oldVal) {
      if (this.$route.query.startDate !== newVal) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            startDate: newVal,
            page: 1,
          },
        });
      }
      if (oldVal) {
        this.$emit('fetchHoliday');
      }
    },
    'filter.endDate': function settoDate(newVal, oldVal) {
      if (this.$route.query.toDate !== newVal) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            toDate: newVal,
            page: 1,
          },
        });
      }
      if (oldVal) {
        this.$emit('fetchHoliday');
      }
    },
  },
  methods: {
    dayjs,
    applyFilters() {
      this.filter.name = this.name;
      if (this.name !== this.$route.query.name) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            name: this.name,
            page: 1,
          },
        });
      }
      this.$emit('fetchHoliday');
    },
  },
};
</script>

// <style lang="scss" scoped>
//   .v-text-field--outlined.v-input--dense .v-input__control .v-text-field__slot > label.v-label {
//     font-size: 10px !important;
//     color: red !important;
//   }
// </style>
