<template>
  <v-container fluid>
    <v-row
      justify="space-between"
      align="center"
    >
      <v-col cols="12" md="7">
        <v-row no-gutters>
          <v-col cols="auto">
            <v-btn
              v-if="userAccess.canCreate"
              color="primary"
              class="mr-2"
              small
              @click="$refs.dialogFormHoliday.dialog = true"
            >
              {{$_strings.holiday.ADD_HOLIDAY}}
            </v-btn>
          </v-col>
          <v-col cols="12" lg="auto" class="mt-2 mt-lg-0">
            <MasterDataCsvUpload
              :importTypeProp="$_strings.importType.HOLIDAY"
              :uploadCsvProp="uploadCsv"
              :downloadTemplateCsvProp="downloadTemplateCsv"
              dataTypeProp="holiday"
              @fetchList="key_list += 1"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="5">
        <FilterList
          :filter="filter"
          @fetchHoliday="fetchHoliday"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :key="key_list"
          :loading="isLoading"
          :headers="displayedHeader"
          :items="items"
          item-key="id"
          :server-items-length="totalItems"
          class="body"
          :options.sync="pagination"
          :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}">
          <template v-slot:[`item.startDate`]={item}>
            <span>{{ dateFormat(item.startDate) }}</span>
          </template>
          <template v-slot:[`item.endDate`]={item}>
            <span>{{ dateFormat(item.endDate) }}</span>
          </template>
          <template v-slot:[`item.actions`]={item}>
            <v-tooltip top v-if="userAccess.canUpdate">
              <template v-slot:activator="{ on, attrs }">
                <v-btn  v-bind="attrs" v-on="on" x-small icon class="mr-2" color="primary" @click="edit(item)" >
                  <v-icon> mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.common.EDIT}}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="userAccess.canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-btn  v-bind="attrs" v-on="on" x-small icon color="red" @click="deleteHoliday(item)" @click.stop.prevent>
                  <v-icon> mdi-trash-can </v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.common.DELETE}}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`footer.page-text`]="props">
            <span>
              {{$_strings.holiday.PAGE_NAME}}
              <span v-if="items.length">
                {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
              </span>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogFormHoliday
      @fetchHoliday="fetchHoliday"
      ref="dialogFormHoliday"
    />
  </v-container>
</template>

<script>
import {
  dateFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import dayjs from 'dayjs';
import DialogFormHoliday from './Dialog/FormHoliday.vue';
import FilterList from './FilterList.vue';

const MasterDataCsvUpload = () => import('../Common/MasterDataCsvUpload');

export default {
  components: {
    DialogFormHoliday,
    FilterList,
    MasterDataCsvUpload,
  },
  data() {
    return {
      key_list: 0,
      isLoading: false,
      items: [],
      searchText: this.$route.query.search || '',
      headers: [
        {
          text: this.$_strings.holiday.HOLIDAY_NAME,
          value: 'name',
        },
        {
          text: this.$_strings.holiday.START_DATE,
          value: 'startDate',
        },
        {
          text: this.$_strings.holiday.END_DATE,
          value: 'endDate',
        },
        {
          text: this.$_strings.common.ACTION,
          value: 'actions',
          sortable: false,
        },
      ],
      pagination: defaultPagination(),
      filter: {
        name: this.$route.query.name || '',
        startDate: this.$route.query.startDate || dayjs(`${dayjs().format('YYYY')}-01-01`).format('YYYY-MM-DD'),
        endDate: this.$route.query.endDate || dayjs(`${dayjs().format('YYYY')}-12-31`).format('YYYY-MM-DD'),
      },
      totalItems: 0,
    };
  },
  computed: {
    displayedHeader() {
      const { canUpdate, canDelete } = this.userAccess;
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
      })).filter((header) => {
        if (!canUpdate && !canDelete) {
          return header.value !== 'actions';
        }
        return header;
      });
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchHoliday();
      },
      deep: true,
    },
  },
  methods: {
    dateFormat,
    skipEmptyStringObject,
    uploadCsv(formData) {
      return this.$_services.holiday.uploadCsv(formData);
    },
    downloadTemplateCsv(type) {
      return this.$_services.holiday.fetchCsvTemplateData(type);
    },
    edit(item) {
      this.$refs.dialogFormHoliday.dialog = true;
      if (item) {
        this.$refs.dialogFormHoliday.holidayId = item.id;
        this.$refs.dialogFormHoliday.form.name = item.name;
        this.$refs.dialogFormHoliday.form.startDate = item.startDate;
        this.$refs.dialogFormHoliday.form.endDate = item.endDate;
      }
    },
    search() {
      if (this.$route.query.search !== this.searchText) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: this.searchText,
          },
        });
      }
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
      } else {
        this.fetchHoliday();
      }
    },
    async fetchHoliday() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        name: '',
        startDate: '',
        endDate: '',
        sort: handleSortBy({ sortBy, sortDesc }),
      };
      if (this.filter.name) filters.name = `qLike(${this.filter.name})`;
      if (this.filter.startDate) filters.startDate = `q>=${this.filter.startDate}`;
      if (this.filter.endDate) filters.endDate = `q<=${this.filter.endDate}`;
      try {
        this.isLoading = true;
        const result = await this.$_services.holiday.holidayList(this.skipEmptyStringObject(filters));
        this.items = result.data.contents;
        this.totalItems = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    deleteHoliday(item) {
      this.$dialog.warning({
        text: `${this.$_strings.holiday.DELETE_HOLIDAY_CONFIRM} "${item.name}"`,
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      }).then((res) => {
        if (!res) return;
        this.$root.$loading.show();
        this.$_services.holiday.deleteHoliday(item.id)
          .then(() => {
            this.$dialog.notify.success(`Berhasil menghapus hari libur "${item.name}"`);
            this.items.splice(this.items.indexOf(item), 1);
          }).finally(() => {
            this.$root.$loading.hide();
          });
      });
    },
  },
};
</script>
