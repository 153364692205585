<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="750"
    >
      <v-card :loading="isLoading">
        <v-card-title>
          <h4>{{$_strings.holiday.PAGE_NAME}}</h4>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-toolbar-items>
                <v-btn
                  icon
                  color="black"
                  dark
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" sm="3">
                <label>{{$_strings.holiday.HOLIDAY_NAME}}</label>
                <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field
                  outlined
                  dense
                  v-model="form.name"
                  :rules="rules.name"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <label class="body-2 ma-0" for="startDate">
                  {{$_strings.holiday.START_DATE}}
                  <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
                </label>
              </v-col>
              <v-col cols="auto" sm="4" class="pt-0">
                <v-menu
                  ref="menuStartDate"
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      id="startDate"
                      dense
                      outlined
                      :value="form.startDate ? dateFormat(form.startDate) : ''"
                      :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Dari Tanggal')]"
                      class="body-2"
                      placeholder="Dari tanggal"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.startDate"
                    no-title
                    :max="form.endDate ? dayjs(form.endDate).format('YYYY-MM-DD') : ''"
                    scrollable
                    @change="menuStartDate = false"
                    locale="id"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="8" sm="5" class="pt-0 d-flex">
                <label for="endDate" class="body-2 mt-2 mr-2">
                  Sampai <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
                </label>
                <v-menu
                  ref="menuEndDate"
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      id="endDate"
                      dense
                      outlined
                      :value="form.endDate ? dateFormat(form.endDate) : ''"
                      :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Sampai Tanggal')]"
                      class="body-2"
                      placeholder="Sampai tanggal"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.endDate"
                    no-title
                    :min="form.startDate ? dayjs(form.startDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')"
                    scrollable
                    locale="id"
                    @change="menuEndDate = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn
                outlined
                class="mr-2 ml-auto"
                @click="dialog = false"
              >
                {{$_strings.common.CANCEL}}
              </v-btn>
              <v-btn
                depressed
                color="primary"
                class="white--text"
                @click="submit"
                :loading="isLoading"
              >
                {{$_strings.common.SAVE}}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dayjs from 'dayjs';
import { dateFormat } from '@/helper/commonHelpers';

export default {
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.$refs.form.reset();
        this.holidayId = null;
        this.form = {
          name: '',
          startDate: null,
          endDate: null,
        };
      }
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      valid: false,
      menuEndDate: false,
      menuStartDate: false,
      holidayId: null, // for update form
      form: {
        name: '',
        startDate: null,
        endDate: null,
      },
      rules: {
        name: [
          (value) => {
            const pattern = /[^A-Za-z0-9\s]/g;
            if (!value) return this.$_strings.messages.validation.REQUIRED(this.$_strings.holiday.HOLIDAY_NAME);
            if (pattern.test(value)) return this.$_strings.messages.validation.UNIQUE_CHARACTER;
            return true;
          },
        ],
      },
    };
  },
  methods: {
    dayjs,
    dateFormat,
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        this.isLoading = true;
        await this.$_services.holiday.createUpdateHoliday(this.form, this.holidayId);
        if (this.holidayId) {
          this.$dialog.notify.success(this.$_strings.holiday.SUCCESS_EDIT_HOLIDAY);
        } else {
          this.$dialog.notify.success(this.$_strings.holiday.SUCCESS_CREATE_HOLIDAY);
        }
        this.dialog = false;
        this.$emit('fetchHoliday');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
