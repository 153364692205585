var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.userAccess.canCreate)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){_vm.$refs.dialogFormHoliday.dialog = true}}},[_vm._v(" "+_vm._s(_vm.$_strings.holiday.ADD_HOLIDAY)+" ")]):_vm._e()],1),_c('v-col',{staticClass:"mt-2 mt-lg-0",attrs:{"cols":"12","lg":"auto"}},[_c('MasterDataCsvUpload',{attrs:{"importTypeProp":_vm.$_strings.importType.HOLIDAY,"uploadCsvProp":_vm.uploadCsv,"downloadTemplateCsvProp":_vm.downloadTemplateCsv,"dataTypeProp":"holiday"},on:{"fetchList":function($event){_vm.key_list += 1}}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('FilterList',{attrs:{"filter":_vm.filter},on:{"fetchHoliday":_vm.fetchHoliday}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{key:_vm.key_list,staticClass:"body",attrs:{"loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"item-key":"id","server-items-length":_vm.totalItems,"options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.startDate)))])]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.endDate)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.userAccess.canUpdate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.edit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.EDIT))])]):_vm._e(),(_vm.userAccess.canDelete)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":"","color":"red"},on:{"click":[function($event){return _vm.deleteHoliday(item)},function($event){$event.stopPropagation();$event.preventDefault();}]}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-trash-can ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.DELETE))])]):_vm._e()]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.holiday.PAGE_NAME)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1),_c('DialogFormHoliday',{ref:"dialogFormHoliday",on:{"fetchHoliday":_vm.fetchHoliday}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }